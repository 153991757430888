// calenderpicker
.customize {
  &.MuiCalendarPicker-root {
    width: 100%;
    max-height: initial;
    overflow: hidden;
    > div:first-child {
      position: relative;
      margin-top: 0;
      margin-bottom: 0.5rem;
      height: 2.5rem;
      min-height: unset;
      max-height: unset;
      > div:first-child {
        width: 98.25%;
        display: flex;
        justify-content: center;
        display: none;
      }
      > div:last-child {
        align-items: center;
        width: 100%;
        height: 2.5rem;
        display: flex;
        justify-content: center;
        padding: 0 3rem;
        gap: 0.5rem;
        position: absolute;
        left: 0;
        button:first-of-type {
          order: 1;
          margin-right: 2rem;
          padding: 0;
        }
        div {
          order: 3;
          display: none;
        }
        button:last-of-type {
          order: 5;
          margin-left: 2rem;
          padding: 0;
        }
        #custom-year,
        #custom-month {
          color: #1672d1;
          font-size: 1.25rem;
          font-weight: bold;
        }
      }
    }
    > div:last-child {
      // 요일 부분
      > div:first-child {
        span {
          flex: 1;
          background-color: #f2f3f5;
          margin: 0;
          height: 2.8rem;
          font-weight: bold;
          font-size: 1rem !important;
          border-right: 1px solid #e0e0e0;
          border-bottom: 1px solid #e0e0e0;
          &:first-child {
            border-left: 1px solid #e0e0e0;
          }
        }
      }

      > div:last-child {
        > div[role='grid'] {
          border-right: none;

          > div[role='row'] {
            margin: 0;
            > div[role='cell'] {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: flex-end;
              width: 14.2857%;
              background-color: white;
              height: 5.4rem;
              cursor: pointer;
              border-right: 1px solid #e0e0e0;
              padding: 0.25rem 0px 0px;
              -webkit-box-pack: justify;
              overflow: hidden;
              position: relative;
              > span:first-child {
                position: absolute;
                top: 0.25rem;
                left: 0.5rem;
                font-size: 1.35rem;
                width: 2.25rem;
                > img {
                  width: 100%;
                }
              }
              > button {
                width: 1.875rem;
                height: 1.875rem;
                margin-right: 0.25rem;
                font-size: 1rem;

                &.MuiPickersDay-today {
                  background-color: #ffff4c;
                  border-color: #ffff4c;
                }
                > span {
                  width: 1.875rem;
                  height: 1.875rem;
                }
                &.Mui-selected {
                  background-color: red;
                }
              }
              > div {
                display: flex;
                width: 100%;
                flex-direction: column;
                gap: 0.075rem;
                span {
                  color: white;
                  display: block;
                  width: 100%;
                  padding: 0.075rem 0rem;
                  cursor: pointer;
                  font-size: 0.875rem;
                }
              }
            }
          }

          > div[role='row']:not(:first-child) {
            border-top: 1px solid #e0e0e0;
          }
        }
      }
    }
  }

  &.side-menu {
    // .active {
    //   + button {
    //     border-top-right-radius: 12px !important;
    //   }
    //   ~ button {
    //     color: white !important;
    //   }
    // }
  }

  .MuiOutlinedInput-root.MuiInputBase-root {
    &.Mui-focused {
      fieldset {
        border-color: #c2c2c2;
      }
    }
  }
}

.MuiBackdrop-root:not(.MuiBackdrop-invisible) {
  opacity: 0.46 !important;
  background-color: #212121 !important;
}

#attendance-status {
  table {
    td {
      button {
        min-width: 0px;
      }
    }
  }
}

a,
img {
  -webkit-user-drag: none !important;
}

img {
  -webkit-touch-callout: none !important;
  pointer-events: none !important;
  -webkit-user-select: none !important;
  -moz-user-select: -moz-none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
