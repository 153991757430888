@font-face {
  font-family: 'Apple SD Gothic Neo';
  font-weight: 300;
  font-style: normal;
  font-display: block;
  src: url('../../assets/fonts/AppleSDGothicNeoL.woff2') format('woff2'),
    url('../../assets/fonts/AppleSDGothicNeoL.woff') format('woff'),
    url('../../assets/fonts/AppleSDGothicNeoL.ttf') format('truetype');
  unicode-range: U+0020-007E, U+1100-11F9, U+3000-303F, U+3131-318E, U+327F-327F, U+AC00-D7A3, U+FF01-FF60;
}

@font-face {
  font-family: 'Apple SD Gothic Neo';
  font-weight: 500;
  font-style: normal;
  font-display: block;
  src: url('../../assets/fonts/AppleSDGothicNeoM.woff2') format('woff2'),
    url('../../assets/fonts/AppleSDGothicNeoM.woff') format('woff'),
    url('../../assets/fonts/AppleSDGothicNeoM.ttf') format('truetype');
  unicode-range: U+0020-007E, U+1100-11F9, U+3000-303F, U+3131-318E, U+327F-327F, U+AC00-D7A3, U+FF01-FF60;
}

@font-face {
  font-family: 'Apple SD Gothic Neo';
  font-weight: 700;
  font-style: normal;
  font-display: block;
  src: url('../../assets/fonts/AppleSDGothicNeoB.woff2') format('woff2'),
    url('../../assets/fonts/AppleSDGothicNeoB.woff') format('woff'),
    url('../../assets/fonts/AppleSDGothicNeoB.ttf') format('truetype');
  unicode-range: U+0020-007E, U+1100-11F9, U+3000-303F, U+3131-318E, U+327F-327F, U+AC00-D7A3, U+FF01-FF60;
}

@font-face {
  font-family: 'Apple SD Gothic Neo';
  font-weight: 800;
  font-style: normal;
  font-display: block;
  src: url('../../assets/fonts/AppleSDGothicNeoEB.woff2') format('woff2'),
    url('../../assets/fonts/AppleSDGothicNeoEB.woff') format('woff'),
    url('../../assets/fonts/AppleSDGothicNeoEB.ttf') format('truetype');
  unicode-range: U+0020-007E, U+1100-11F9, U+3000-303F, U+3131-318E, U+327F-327F, U+AC00-D7A3, U+FF01-FF60;
}

@font-face {
  font-family: 'GmarketSans';
  font-weight: 400;
  font-style: normal;
  font-display: block;
  src: local('Gmarket Sans Light'), local('GmarketSans-Light'),
    url('https://script.ebay.co.kr/fonts/GmarketSansLight.woff2') format('woff2'),
    url('https://script.ebay.co.kr/fonts/GmarketSansLight.woff') format('woff');
  unicode-range: U+0020-007E, U+1100-11F9, U+3000-303F, U+3131-318E, U+327F-327F, U+AC00-D7A3, U+FF01-FF60;
}

@font-face {
  font-family: 'GmarketSans';
  font-weight: 700;
  font-style: normal;
  font-display: block;
  src: local('Gmarket Sans Medium'), local('GmarketSans-Medium'),
    url('https://script.ebay.co.kr/fonts/GmarketSansMedium.woff2') format('woff2'),
    url('https://script.ebay.co.kr/fonts/GmarketSansMedium.woff') format('woff');
  unicode-range: U+0020-007E, U+1100-11F9, U+3000-303F, U+3131-318E, U+327F-327F, U+AC00-D7A3, U+FF01-FF60;
}

@font-face {
  font-family: 'GmarketSans';
  font-weight: 800;
  font-style: normal;
  font-display: block;
  src: local('Gmarket Sans Bold'), local('GmarketSans-Bold'),
    url('https://script.ebay.co.kr/fonts/GmarketSansBold.woff2') format('woff2'),
    url('https://script.ebay.co.kr/fonts/GmarketSansBold.woff') format('woff');
  unicode-range: U+0020-007E, U+1100-11F9, U+3000-303F, U+3131-318E, U+327F-327F, U+AC00-D7A3, U+FF01-FF60;
}

@font-face {
  font-family: 'OSeongandHanEum';
  font-weight: 400;
  font-style: normal;
  font-display: block;
  src: url('../../assets/fonts/OSeongandHanEum-Regular.otf') format('opentype'),
    url('../../assets/fonts/OSeongandHanEum-Regular.ttf') format('truetype');
  unicode-range: U+0020-007E, U+1100-11F9, U+3000-303F, U+3131-318E, U+327F-327F, U+AC00-D7A3, U+FF01-FF60;
}

@font-face {
  font-family: 'OSeongandHanEum';
  font-weight: 700;
  font-style: normal;
  font-display: block;
  src: url('../../assets/fonts/OSeongandHanEum-Bold.otf') format('opentype'),
    url('../../assets/fonts/OSeongandHanEum-Bold.ttf') format('truetype');
  unicode-range: U+0020-007E, U+1100-11F9, U+3000-303F, U+3131-318E, U+327F-327F, U+AC00-D7A3, U+FF01-FF60;
}

// @font-face {
//   font-family: 'BMJUA';
//   src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_one@1.0/BMJUA.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'NanumSquareRound';
//   src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_two@1.0/NanumSquareRound.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'TTTogether';
//   src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/TTTogetherA.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
// }

// @import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');

// @font-face {
//   font-family: 'GmarketSansBold';
//   src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansBold.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
// }

@font-face {
  font-family: 'TTTogether';
  src: url('../../assets/fonts/TTTogether.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

//! 프리텐다드
@font-face {
  font-family: 'Pretendard';
  src: url('../../assets/fonts/Pretendard-Thin.woff') format('woff');
  font-weight: 100;
}
@font-face {
  font-family: 'Pretendard';
  src: url('../../assets/fonts/Pretendard-ExtraLight.woff') format('woff');
  font-weight: 200;
}
@font-face {
  font-family: 'Pretendard';
  src: url('../../assets/fonts/Pretendard-Light.woff') format('woff');
  font-weight: 300;
}
@font-face {
  font-family: 'Pretendard';
  src: url('../../assets/fonts/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
}
@font-face {
  font-family: 'Pretendard';
  src: url('../../assets/fonts/Pretendard-Medium.woff') format('woff');
  font-weight: 500;
}
@font-face {
  font-family: 'Pretendard';
  src: url('../../assets/fonts/Pretendard-SemiBold.woff') format('woff');
  font-weight: 600;
}
@font-face {
  font-family: 'Pretendard';
  src: url('../../assets/fonts/Pretendard-Bold.woff') format('woff');
  font-weight: 700;
}
@font-face {
  font-family: 'Pretendard';
  src: url('../../assets/fonts/Pretendard-ExtraBold.woff') format('woff');
  font-weight: 800;
}
@font-face {
  font-family: 'Pretendard';
  src: url('../../assets/fonts/Pretendard-Black.woff') format('woff');
  font-weight: 900;
}

@font-face {
  font-family: 'NanumSquareRound';
  src: url('../../assets/fonts/NanumSquareRoundB.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
