$colors: (
  red: #e9121d,
  danger: #e9121d,
  pink: #fa63be,
  hot-pink: #ff65bd,
  green: #2ece73,
  yellow-green: #32ea88,
  orange: #fc6500,
  deep-orange: #ff6c59,
  lignt-orange: #ffb787,
  yellow: #fcbe00,
  yellow-2: #ffe100,
  blue: #2baed1,
  blue-2: #4481c5,
  sky-blue: #e3f8ff,
  purple: #b85bff,
  gray-1: #9f9f9f,
  gray-2: #dbdbdb,
  gray-3: #ebebeb,
  gray-4: #f0f0f0,
  gray-5: #f8f9fa,
  gray-6: #cacaca,
  cyan: #2baed1,
  cyan-2: #4fb9c0,
  light-cyan: #e3f8ff,
  black: #000000,
  white: #ffffff,
);

$sides: (
  'l': 'left',
  'r': 'right',
  't': 'top',
  'b': 'bottom',
  'a': 'all',
);

@each $colorKey, $colorValue in $colors {
  // background 컬러
  .bg-#{$colorKey} {
    background-color: $colorValue !important;
  }

  // text 컬러
  .text-#{$colorKey} {
    color: $colorValue !important;
  }

  // border 컬러
  @each $sideKey, $sideValue in $sides {
    @if ($sideKey != 'a') {
      .border-#{$sideKey}-#{$colorKey} {
        border-#{$sideValue}: 1px solid;
        border-color: #{$colorValue};
      }
    } @else if ($sideKey == 'a') {
      .border-#{$colorKey} {
        border: 1px solid;
        border-color: $colorValue;
      }
    }
  }
}
