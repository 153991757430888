@import '_fonts';
@import '_mixin';
@import '_variables';
@import '_color_class';
@import 'customize';

* {
  font-family: $font-family-apple !important;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  letter-spacing: -0.425px;
  &:not(.show-scrollbar)::-webkit-scrollbar {
    display: none;
  }
}

.MuiFormGroup-root,
.custom_scroll_box {
  &::-webkit-scrollbar {
    display: block !important;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: #e1e2e3;
    border-radius: 10px;
  }
}

.small-loader {
  // --b: 10px; /* border thickness */
  // --n: 10; /* number of dashes*/
  // --g: 10deg; /* gap  between dashes*/
  // --c: red; /* the color */
  --b: 6px;
  --c: #fff;
  --n: 15;
  --g: 7deg;
  width: 1.25rem;
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 1px; /* get rid of bad outlines */
  background: conic-gradient(#0000, var(--c)) content-box;
  --_m: /* we use +/-1deg between colors to avoid jagged edges */ repeating-conic-gradient(
      #0000 0deg,
      #000 1deg calc(360deg / var(--n) - var(--g) - 1deg),
      #0000 calc(360deg / var(--n) - var(--g)) calc(360deg / var(--n))
    ),
    radial-gradient(farthest-side, #0000 calc(98% - var(--b)), #000 calc(100% - var(--b)));
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
  animation: load 1s infinite steps(var(--n));
}
@keyframes load {
  to {
    transform: rotate(1turn);
  }
}

html {
  box-sizing: border-box;
  -webkit-user-drag: none;
  // @include respond-below(sm) {
  //   font-size: $base-font-xs + px !important;
  // }
  // @include respond-between(sm, md) {
  //   font-size: $base-font-sm + px !important;
  // }
  // @include respond-between(md, lg) {
  //   font-size: $base-font-md + px !important;
  // }
  // @include respond-between(lg, xl) {
  //   font-size: $base-font-lg + px !important;
  // }
  // @include respond-above(xl) {
  //   font-size: $base-font-xl + px !important;
  // }
  @include respond-below(xs) {
    font-size: $base-font-xs + px !important;
    // line-height: ($base-font-xs + ($base-font-xs/5)) + px !important;
  }
  @include respond-between(xs, sm) {
    font-size: $base-font-sm + px !important;
    // line-height: ($base-font-sm + ($base-font-sm/5)) + px !important;
  }
  @include respond-between(ssm, sm) {
    font-size: $base-font-ssm + px !important;
    // line-height: ($base-font-sm + ($base-font-sm/5)) + px !important;
  }
  @include respond-between(sm, md) {
    font-size: $base-font-md + px !important;
    // line-height: ($base-font-md + ($base-font-md/5)) + px !important;
  }
  @include respond-between(md, lg) {
    font-size: $base-font-lg + px !important;
    // line-height: ($base-font-lg + ($base-font-lg/5)) + px !important;
  }
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
}

ul,
li,
ol {
  list-style: none;
}

h3 {
  font-size: 1.3rem;
}
h4 {
  font-size: 1.05rem;
}

body.counsel_print_container {
  .counsel_container {
    & .checked_counsel:last-child {
      margin-bottom: 0;
    }
    .checked_counsel {
      margin-bottom: 14.25px;
      background-color: rgb(255, 255, 255);
      position: relative;
      border: 1px solid rgb(235, 235, 235);
      border-radius: 7px;
      padding: 24.8888px 2.5rem 24.8888px 2.25rem;
      display: flex;

      & > div:nth-child(2) {
        height: 100%;
        width: 3.25rem;

        & > div {
          width: 3.25rem;
          height: 3.25rem;
          margin: 0 auto;
          overflow: hidden;
          border-radius: 50%;
          border: 1px solid #dbdbdb;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          img {
            max-width: 90%;
            max-height: 90%;
          }
        }
      }

      & > div:last-child {
        height: 100%;
        width: calc(100% - 2.75rem);
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        justify-content: space-between;
        flex-direction: column;

        & > div:first-child {
          font-weight: 500;
          font-size: 14px;
          padding-left: 1rem;
          width: 100%;
          height: 37.3333px;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          justify-content: space-between;

          & > div {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: end;
            justify-content: flex-end;

            & > div:first-child {
              font-size: 14px;
              margin-right: 1rem;
            }
          }
        }

        & > div:last-child {
          width: 100%;

          & > div {
            padding: 12.4444px;
            color: #9f9f9f;
            font-size: 14px;
            white-space: pre-wrap;
            line-height: 18.6666px;
          }
        }
      }
    }
  }
}

body.iframe_body {
  .iframe_print_wrap {
    width: 100%;
    height: 100%;
    display: flex;
    background-color: #f8f9fd !important;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 3rem;
  }

  .check_analyze_print {
    // padding-left: 1px;
    width: 85%;
    height: 82.26%;
    margin: 0 auto;
  }
  #analyze_wrap {
    width: 100%;
    height: 100%;
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
    font-size: 16px !important;
    gap: 1.25rem;
    display: flex;
    flex-direction: column;
    position: relative;

    & > div:first-child {
      display: flex;
      padding-top: 1rem;
      flex-wrap: wrap;
      gap: 1.6rem 0;
      font-size: 1.5rem;
      width: 100%;
      height: 24.645%;
      & > div:first-child {
        margin-right: 0.85rem;
      }
      & > div:nth-child(2) {
        margin-left: 0.8rem;
      }
      & > div:first-child,
      & > div:nth-child(2) {
        height: 100%;
        padding: 1rem 1.75rem 1.75rem 1.75rem;
        background-color: #fff;
        border-radius: 1rem;
        width: calc(50% - 0.825rem);
        box-shadow: rgba(0, 0, 0, 0.02) 1px 1px 3px 6px;
        & > div:first-child {
          width: 100%;
          padding: 0 0 0.5rem 0;
          border-bottom: 1px solid #f1f1f1;
          font-weight: bold;
          min-height: 2rem;
        }
        & > div:last-child {
          margin-top: 0.125rem;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          position: relative;
          width: 100%;
          height: calc(100% - 2rem);
          & > div {
            margin-top: 0.75rem;
            padding-left: 0.5rem;
            line-height: 1rem;
            & > div:first-child {
              color: #ff382a;
            }
            & > div:last-child {
              margin-top: 1rem;
              color: #7d7d7d;
              & > span {
                color: #d3d3d3;
              }
            }
          }
        }
      }
      & > div:last-child {
        padding: 1rem 0 0 0;
        height: 100%;
        background-color: #fff;
        border-radius: 1rem;
        padding-right: 0;
        box-shadow: rgba(0, 0, 0, 0.02) 1px 1px 3px 6px;
        width: calc(50% - 0.825rem);
        margin-right: 0.85rem;
        position: relative;
        & > div:first-child {
          width: auto;
          padding: 0 0 0.5rem 0;
          border-bottom: 1px solid #f1f1f1;
          font-weight: bold;
          min-height: 2rem;
          margin: 0 1.75rem;
        }
        & > div:last-child {
          margin-top: 0.125rem;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          position: relative;
          width: 100%;
          height: calc(100% - 2rem);
          & > div:first-child {
            // transform: scale(1.25);
            top: 15%;
            // left: 17%;
            position: relative;
            text {
              font-size: 3.5rem !important;
            }
          }
          & > div:last-child {
            width: 100%;
            height: 100%;
            // transform: scale(1.05);

            // left: 17.75%;
            position: absolute;
            & > div {
              & > div {
                & > svg {
                  & > g {
                    & > g.recharts-pie-sector {
                      & > path:first-of-type {
                        opacity: 0;
                      }
                    }
                    & > g.recharts-pie-labels {
                      & > g {
                        & > path:first-of-type {
                          opacity: 0;
                        }
                      }
                    }
                  }
                }
                & > svg:not(:root) {
                  overflow: visible;
                }
              }
            }
            text {
              font-size: 14px !important;
            }
          }
        }
      }
    }
    & > div:nth-child(2) {
      display: flex;
      box-shadow: rgba(0, 0, 0, 0.02) 1px 1px 3px 6px;
      border-radius: 1rem;
      overflow: hidden;
      margin-right: auto;
      width: 49%;
      margin-top: 0.55%;
      height: 23.5%;
      flex-wrap: wrap;
      margin-left: 51%;
      & > div {
        width: 10%;
        height: 33.3333%;
        & > div:first-child {
          height: 50%;
          background-color: #f7f7f7;
          border-right: 1px solid #e8e8e8 !important;
          border-bottom: 1px solid #e8e8e8;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.75rem;
        }
        & > div:last-child {
          height: 50%;
          font-size: 1.75rem;
          border-right: 1px solid #e8e8e8 !important;
          line-height: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          // color: #fff;
          // background-color: #ff6c59;
        }
      }
    }
    & > div:nth-child(3),
    & > div:last-child {
      margin-top: 4px;
      box-shadow: rgba(0, 0, 0, 0.02) 1px 1px 3px 6px;
      background-color: #fff;
      padding-top: 13px;
      padding-bottom: 5px;
      border-radius: 1rem;
      height: 23%;
      width: 100%;
      position: relative;
      & > div:first-child {
        font-weight: 700;
        margin-bottom: -6px;
        padding-left: 1.5rem;
        font-size: 1.275rem;
      }
      & > div:last-child {
        width: 100%;
        height: 80%;
        transform: translateX(-16px);
        & > div {
          transform: scale(0.975, 0.9);
        }
      }
      tspan {
        font-size: 1.275rem;
      }
    }
    // #analyze_contents_top {
    //   height: 32%;
    //   -webkit-box-pack: justify;
    //   justify-content: space-between;
    //   display: flex;
    //   width: 100%;
    //   .analyze_student_info {
    //     font-size: 16px !important;
    //     width: 100%;
    //     height: 20%;
    //     display: flex;
    //     &:first-child {
    //       &:first-child {
    //         border-top: none;
    //       }
    //       &:last-child {
    //         border-top: none;
    //       }
    //     }
    //     & > div:first-child {
    //       width: 35%;
    //       padding: 1rem;
    //       border-top: 1px solid #dbdbdb;
    //       border-right: 1px solid #dbdbdb;
    //       background-color: #f0f0f0;
    //       display: flex;
    //       justify-content: center;
    //       align-items: center;
    //     }
    //     & > div:last-child {
    //       width: 65%;
    //       padding: 1rem;
    //       border-top: 1px solid #dbdbdb;
    //       background-color: #fff;
    //       display: flex;
    //       justify-content: flex-start;
    //       align-items: center;
    //     }
    //   }
    //   .recharts-pie-labels {
    //     g {
    //       g {
    //         text {
    //           font-size: 18px !important;
    //         }
    //       }
    //     }
    //   }
    //   & > div {
    //     width: 49%;
    //     height: 100%;
    //     border: 2px solid #dbdbdb;
    //     border-radius: 0.35rem;
    //   }
    //   & > div:first-child {
    //     flex-wrap: wrap;
    //     display: flex;
    //   }
    //   & > div:last-child {
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     position: relative;
    //     & > div:last-child {
    //       width: 100%;
    //       height: 100%;
    //       position: absolute;
    //       z-index: -99;
    //       top: 50%;
    //       left: 50%;
    //       transform: translate(-50%, -50%);
    //     }
    //   }
    // }
    // #analyze_contents_middle {
    //   width: 100%;
    //   height: 15%;
    //   border: 1px solid #dbdbdb;
    //   border-radius: 0.45rem;
    //   overflow: hidden;
    //   display: flex;
    //   & > div {
    //     width: 100%;
    //     height: 100%;
    //     & > div:first-child {
    //       height: 50%;
    //       background-color: #f6f6f6;
    //       border-right: 1px solid #dbdbdb !important;
    //       border-bottom: 1px solid #dbdbdb;
    //       display: flex;
    //       justify-content: center;
    //       align-items: center;
    //     }
    //     & > div:last-child {
    //       height: 50%;
    //       font-size: 1.125rem;
    //       border-right: 1px solid #dbdbdb !important;
    //       line-height: 0;
    //       display: flex;
    //       justify-content: center;
    //       align-items: center;
    //       &[result = 'success'] {
    //         color: #fff;
    //         background-color: #71c5ff;
    //       }
    //       &[result = 'false' ] {
    //         color: #fff;
    //         background-color: #ff6c59;
    //       }
    //       &[result = 'null'] {
    //         background-color: #fff;
    //       }
    //       &:last-child {
    //         & > div {
    //           border-right: none;
    //         }
    //       }
    //     }
    //   }
    // }
    // #analyze_contents_bottom {
    //   width: 100%;
    //   height: 53%;
    //   border: 1px solid #dbdbdb;
    //   border-radius: 0.45rem;
    //   padding: 1rem;
    //   overflow: hidden;
    //   & > div:first-child {
    //     font-weight: 700;
    //     margin-bottom: 0.5rem;
    //     font-size: 1.75rem;
    //     padding-left: 0.5rem;
    //   }
    // }
  }
}

body.study_print_container {
  .iframe_print_wrap {
    width: 100%;
    height: 100%;
    min-height: 297mm;
    display: flex;
    background-color: #f8f9fd !important;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .study_container {
    & > div.study-report {
      height: auto;
      padding: 28px;
      background-color: #fff;
      border: 1px solid #ebebeb;
      border-radius: 6px;
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }
      & > div.study-report__header {
        height: auto;
        width: 100%;
        margin-bottom: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        & > div {
          height: auto;
          font-weight: bold;
        }
      }
      .study-report__title {
        max-width: 80%;
      }
      .study-report__createdAt {
        width: 20%;
        text-align: right;
      }
      & > div.study-report__contents {
        height: auto;
        width: 100%;
        color: #979797;
        line-height: 18px;
        & > p {
          width: 100%;
          min-height: 18px;
        }
      }
      & > div.study-report__footer {
        height: auto;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 12px;
      }
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-webkit-autofill::first-line {
  font-size: 1rem;
}

#card-link-to-class,
#card-link-to-create-book,
#card-link-to-deploy-book,
#card-link-to-testmaking {
  color: #6b90d2;
  a:visited {
    color: #6b90d2;
  }
  a:hover {
    color: #6b90d2;
  }
  a:link {
    color: #6b90d2;
  }
  a:active {
    color: #6b90d2;
  }
}

@media print {
  @page {
    size: A4;
    margin: 0;
    padding: 0;
  }

  * {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
    color-adjust: exact !important; /*Firefox*/
    &::-webkit-scrollbar {
      display: none;
    }
  }

  html,
  body {
    width: unset !important;
    height: unset !important;
    margin: 0 !important;
    padding: 0 !important;
    min-width: unset !important;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .nonprint {
    display: none !important;
  }
}

@keyframes blink-effect {
  0%,
  77% {
    opacity: 1;
  }
  78%,
  100% {
    opacity: 0;
  }
}

.blink-text {
  animation: blink-effect 1.3s step-end infinite;
}

.hidden-mission-dots {
  display: flex !important;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  position: absolute;
  width: 100%;
  top: 34.5rem;
}

.hidden-mission-dots button {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  border: none;
  color: transparent;
  background-color: #d6d6d6;
}

@media (max-width: 959px) {
  .hidden-mission-dots {
    top: 29.25rem;
    bottom: auto;
  }
  .hidden-mission-dots button {
    width: 6px;
    height: 6px;
  }
}

@media (max-width: 767px) {
  .hidden-mission-dots {
    top: 20.25rem;
    bottom: auto;
  }
  .hidden-mission-dots button {
    width: 4px;
    height: 4px;
  }
}

.hidden-mission-dots li.slick-active button {
  background-color: #ffcd47;
}

.hidden-mission-slick .slick-arrow {
  visibility: hidden;
}

.pretendard {
  font-family: 'Pretendard' !important;
}

.small-kor {
  .g_kor_span {
    font-size: 80% !important;
  }
}

.result-zoom {
  border-bottom: 1px solid #616161;
  top: 4px;
  margin-left: 2px;
  margin-right: 2px;
  &.example {
    border-color: #9e9e9e;
  }
}

.white--text {
  color: #fff !important;
  caret-color: #fff !important;
}

.sa_input {
  background-color: #f5f5f5;
  border-radius: 35px;
  padding: 8px 16px;
  height: 100%;
  &:focus-visible {
    outline: none;
  }
}

.d-inline-block {
  display: inline-block;
}

.pos-rel {
  position: relative;
}

.info2--text {
  color: #5bc7ff;
}

.u-l {
  text-decoration: underline;
  text-underline-position: under;
}
