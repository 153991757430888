:root {
  --red: #e9121d;
  --danger: #e9121d;
  --green: #2ece73;
  --yellow-green: #32ea88;
  --orange: #fc6500;
  --deep-orange: #ff6c59;
  --yellow: #fcbe00;
  --yellow-2: #ffe100;
  --blue: #2baed1;
  --blue-2: #4481c5;
  --sky-blue: #e3f8ff;
  --purple: #b85bff;
  --gray-1: #9f9f9f;
  --gray-2: #dbdbdb;
  --gray-3: #ebebeb;
  --gray-4: #f0f0f0;
  --gray-5: #f8f9fa;
  --cyan: #2baed1;
  --cyan-2: #4fb9c0;
  --cyan-3: #77d5fe;
  --light-cyan: #e3f8ff;
  --black: #000000;
  --white: #ffffff;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  --font-family-apple: 'Apple SD Gothic Neo', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

$breakpoints: (
  xs : 600px,
  ssm : 768px,
  sm : 960px,
  md : 1264px,
  lg : 1904px
);

@mixin respond-above($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @media (min-width: $breakpoint-value) {
      @content;
    }
  } @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//  RESPOND BELOW
@mixin respond-below($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
  } @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}
//  RESPOND BETWEEN
@mixin respond-between($lower, $upper) {
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }
  } @else {
    @if (map-has-key($breakpoints, $lower) == false) {
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }
    @if (map-has-key($breakpoints, $upper) == false) {
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}

